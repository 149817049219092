<template>
<div  class="ma-5" style="position:relative;">
    <div style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;">
    <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
    />
    <p
        :class="$vuetify.theme.dark?'subtitle-1 primary--text':'subtitle-1 black--text'"
    >Cargando Data</p>
    </div>
    <v-container>
        <v-row>
            <v-col cols="4" sm="4" md="4">
                <v-skeleton-loader
                    ref="skeleton"
                    type="image" 
                    class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
            </v-col>

            <v-col cols="4" sm="4" md="4">
                <v-skeleton-loader
                    ref="skeleton"
                    type="image" 
                    class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
            </v-col>

            <v-col cols="4" sm="4" md="4">
                <v-skeleton-loader
                    ref="skeleton"
                    type="image" 
                    class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";

export default {
    name: "skeleton",
    components: {
        lottie: Lottie,
    },
     data() {
        return {
             animationSpeed: 1.1,
            defaultOptions: {
                animationData: animationData.default,
                loop: true,
                autoplay: true
            }
        }
     },
    methods : {
        handleAnimation: function(anim) {
            this.anim = anim;
            anim.setSpeed(this.animationSpeed);
        }
    }
}
</script>